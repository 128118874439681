* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-font-smoothing: antialiased;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

:root {
  --primary-b: #3772ff;
  --background-b: #18191d;
  --background-1: #23262f;
  --background-2: #353945;
  --text-color: #fcfcfd;
  --low-opacity-text: #777e90;
  --light-b: #e8e8e8;
  --transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  --max-width: 640px;
}

#root {
  overflow: hidden;
  max-width: var(--max-width);
  margin: auto;
}

.App {
  background-color: var(--background-b);
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  --dodger-blue: #3772ff;
  --burnt-sienna: #ef8262;
  --medium-purple: #7469e3;
  --chateau-green: #45b26b;
  --french-rose: #ef466f;
}
.App.dark-mode {
  --primary-b: #3772ff;
  --background-b: #18191d;
  --background-1: #23262f;
  --background-2: #353945;
  --background-3: #141416;
  --text-color: #fcfcfd;
  --low-opacity-text: #89899a;
  --light-b: #e8e8e8;
  --transition: 300ms ease-out;
}
.App.light-mode {
  --primary-b: #3772ff;
  --background-b: #f5f5f5;
  --background-1: #ededed;
  --background-2: #fff;
  --text-color: #1d212c;
  --low-opacity-text: #67708d;
  --light-b: #e8e8e8;
  --transition: 300ms ease-out;
}
.App.light-mode .page_book .top_bar .MuiIconButton-root {
  color: #fff !important;
}
.App.light-mode #bookPageHeader {
  color: #fff;
}
.App.light-mode #bookPageHeader img {
  opacity: 0.95 !important;
}
.App.light-mode .update_container::before {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.App.light-mode .page_login .logo-container svg path {
  fill: #1d212c;
}
.App.light-mode .page_login .loading-container {
  display: none;
}
.App.light-mode .container.filter-container::before {
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#f5f5f5));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f5f5f5 100%);
}
.App.light-mode .page#addNewForm .top-filter {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#f5f5f5));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f5f5f5 100%);
}
.App.dodger-blue {
  --primary-b: #3772ff;
}
.App.burnt-sienna {
  --primary-b: #ef8262;
}
.App.medium-purple {
  --primary-b: #7469e3;
}
.App.chateau-green {
  --primary-b: #45b26b;
}
.App.french-rose {
  --primary-b: #ef466f;
}

body {
  background-color: var(--background-b);
  color: var(--text-color);
  font-family: "Poppins", serif;
  -webkit-tap-highlight-color: transparent;
  min-height: 100% !important;
}
body.no-scroll {
  overflow: hidden;
}
body.dark-mode {
  --background-b: #18191d;
}
body.light-mode {
  --background-b: #f5f5f5;
}

small {
  color: var(--low-opacity-text);
}

img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.page {
  margin: auto;
  padding-bottom: 4rem;
  min-height: 100vh;
  max-width: var(--max-width);
  width: 100%;
}
.page.page_book {
  margin-bottom: 0;
  padding-bottom: 4.25rem;
}
.page.page_book .top_bar {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.page.page_book.without-btn {
  padding-bottom: 1.5rem;
}
.page.page_home .bookContainer {
  padding-top: 0;
}
.page.empty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}

h1 {
  font-weight: 500;
  letter-spacing: -0.5px;
}

h2 {
  letter-spacing: -0.3px;
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  line-height: 1.2em;
}

h3 {
  font-size: 1rem;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.Navigation {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 64px;
  padding: 0;
  background-color: var(--background-b);
  z-index: 999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  max-width: var(--max-width);
  margin: auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  overflow: hidden;
}
.Navigation a {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.Navigation a.active_page .MuiIconButton-root {
  color: var(--text-color) !important;
}
.Navigation .MuiIconButton-root {
  color: var(--low-opacity-text) !important;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 64px;
  height: 64px;
}
.Navigation .MuiIconButton-root .MuiTouchRipple-root {
  height: 96px;
  width: 96px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 1;
}
.Navigation .MuiIconButton-root.addNew {
  color: var(--primary-b) !important;
  opacity: 1 !important;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 64px;
  width: 64px;
}

.container {
  padding: 1.5rem;
  width: 100%;
  z-index: 9;
}
.container.no-padding {
  padding: 0 1.5rem;
}
.container.no-padding-top {
  padding-top: 0;
}
.container.no-padding-bottom {
  padding-bottom: 0;
}
.container .container-top {
  padding-top: 1.5rem;
  width: 100%;
}

.bookContainer {
  padding: 1.5rem 1.5rem 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.bookContainer .swiper-container {
  overflow: visible;
}
.bookContainer .BookLink {
  text-decoration: none;
  color: var(--text-color);
}
.bookContainer .collection {
  margin-bottom: 1rem;
  background-color: var(--background-1);
  padding: 1.5rem;
  border-radius: 12px;
}
.bookContainer .collection h2 {
  margin-bottom: 8px;
}
.bookContainer .collection small {
  max-width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Book {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5rem;
}
.Book img {
  width: 112px;
  height: 172px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  background-color: var(--background-1);
  border: 0;
  margin-right: 1rem;
}
.Book .info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Book .info .title {
  margin: 0;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Book .info .author {
  color: var(--low-opacity-text);
  font-size: 14px;
  font-weight: 400;
}
.Book .info .chip-list {
  padding: 0;
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  max-width: 200px;
}
.Book .info .chip-list .chip {
  overflow: hidden;
  max-width: 100%;
}
.Book .info .chip-list .chip span {
  white-space: nowrap;
  max-width: 20ch;
  font-size: 10px;
}

.swiper-container {
  width: 100%;
}
.swiper-container .swiper-slide .BookLink {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.swiper-container .swiper-slide .Book {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-bottom: 0;
}
.swiper-container .swiper-slide .Book:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.swiper-container .swiper-slide .Book img {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  height: 86vw;
  margin-bottom: 1rem;
  max-height: 400px;
}
.swiper-container .swiper-slide .Book .info .about {
  display: none;
}
.swiper-container .swiper-slide .Book .info .title {
  margin-bottom: 0.5rem;
}
.swiper-container .swiper-slide .Book .info .author {
  margin-bottom: 4px;
}
.swiper-container .swiper-slide .Book .info .stats {
  z-index: 9;
}
.swiper-container .swiper-slide .Book .info .stats .top {
  display: none;
}

.stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 9;
}
.stats .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.stats .progressBar {
  height: 0.5rem;
  width: 100%;
  background-color: var(--background-1);
  border-radius: 1rem;
  margin-top: 0.5rem;
  overflow: hidden;
}
.stats .progressBar .bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  background: var(--primary-b);
  border-radius: 1rem;
  width: 0;
}

#addNew {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2.5rem;
  background-color: var(--background-1);
  z-index: 99;
  border-radius: 1rem 1rem 0 0;
}
#addNew ul {
  margin: 0;
  padding: 0;
}
#addNew ul li {
  list-style-type: none;
  padding: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 20px;
}
#addNew ul li .MuiSvgIcon-root {
  margin-right: 1rem;
}

#addNewOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 9;
}

.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 4rem;
  background-color: var(--primary-b);
  color: var(--light-b);
  border: 0;
  font-size: 18px;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.btn:disabled {
  background-color: var(--low-opacity-text);
  color: var(--background-b);
}
.btn:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

/* #addNewForm {
  display: flex;
  align-items: center;
  padding-bottom: 0;

  .container {
    &.form-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &.top_bar {
      flex-direction: row;
      justify-content: flex-start;
    }

    .main-image {
      max-width: 200px;
      min-height: 300px;
      border-radius: 12px;
      margin-bottom: 1rem;
      z-index: 9;
      margin-bottom: 3rem;
    }

    .add-form {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      width: 100%;
    }
  }

  .form-control {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;

    input[type="text"],
    input[type="number"] {
      background-color: transparent;
      color: var(--text-color);
      border: 0;
      font-size: 1.2rem;
      height: fit-content;
      text-align: left;
      padding: 0;
      outline: 0;
      text-align: center;
      color: var(--low-opacity-text);
      max-width: 100%;
      width: 100%;
      user-select: text;
      -webkit-user-select: text;

      &#title {
        font-size: 2rem;
        height: 2rem;
        color: var(--text-color);
      }
    }

    &.hidden {
      display: none;
    }
  }

  .btn-container {
    position: absolute;
    bottom: 0;
    left: 0;
  }
} */
.logs .log {
  padding: 1rem;
  width: 100%;
  background-color: var(--background-1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--text-color);
  margin-bottom: 12px;
  border-radius: 12px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.logs .log .title {
  max-width: 18ch;
}
.logs .log .date {
  color: var(--low-opacity-text);
  font-size: 12px;
  margin-top: 4px;
  font-weight: 500;
}
.logs .log:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.logs .log .pages-read {
  white-space: nowrap;
}
.logs a:last-of-type .log {
  margin-bottom: 0;
}
.logs.latest {
  padding-bottom: 0;
}

.top_bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  top: 0;
  z-index: 99;
  max-width: var(--max-width);
}
.top_bar .MuiIconButton-root {
  background-color: var(--background-1);
  background-color: rgba(248, 249, 254, 0.1);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.top_bar .MuiIconButton-root {
  color: var(--text-color) !important;
}

#bookPageHeader {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5rem;
  background: var(--background-b);
  z-index: 0;
  padding-bottom: 0;
}
#bookPageHeader::after {
  content: "";
  position: absolute;
  bottom: -32px;
  left: 0;
  height: 32px;
  width: 100%;
  background-color: green;
  background: var(--background-b);
  display: none;
}
#bookPageHeader::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: green;
  background: var(--background-b);
  opacity: 1;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(29, 33, 44, 0)), to(var(--background-b)));
  background: linear-gradient(180deg, rgba(29, 33, 44, 0) 0%, var(--background-b) 100%);
  z-index: 0;
}
#bookPageHeader .background {
  position: absolute;
  height: 100%;
  width: 100%;
}
#bookPageHeader .background .filter {
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(29, 33, 44, 0.08);
  opacity: 1;
}
#bookPageHeader .img-wrapper {
  z-index: 9;
}
#bookPageHeader img {
  -o-object-fit: cover;
     object-fit: cover;
  background-color: var(--background-1);
}
#bookPageHeader img.background-image {
  position: absolute;
  z-index: -1;
  opacity: 0.4;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
#bookPageHeader img.main-image {
  max-width: 170px;
  min-height: 260px;
  border-radius: 12px;
  margin-bottom: 1rem;
  z-index: 9;
}
#bookPageHeader .title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 0;
  z-index: 1;
  max-width: 17ch;
}
#bookPageHeader .author {
  color: var(--light-b);
  font-weight: 400;
  margin-top: 0.5rem;
  font-size: 14px;
  z-index: 1;
  text-align: center;
  opacity: 0.75;
}
#bookPageHeader .progressBar {
  background-color: rgba(232, 232, 232, 0.16);
}
#bookPageHeader .chip-container {
  padding: 1.5rem 0 0 0;
}
#bookPageHeader .chip-list {
  padding: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#bookPageHeader .chip-list .chip {
  background-color: var(--background-1);
  background-color: rgba(248, 249, 254, 0.1);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border: 0;
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
}

.book_logs {
  position: relative;
  background-color: var(--background-b);
  padding-bottom: 2rem;
  z-index: 1;
}
.book_logs h2 {
  margin-bottom: 1.5rem;
}
.book_logs.withoutBtn {
  padding-bottom: 0;
}
.book_logs .no_logs {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-bottom: 0;
}

.update_container {
  position: fixed;
  bottom: 0;
  z-index: 9;
  max-width: var(--max-width);
}
.update_container::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(29, 33, 44, 0)), to(var(--background-b)));
  background: linear-gradient(180deg, rgba(29, 33, 44, 0) 0%, var(--background-b) 100%);
  z-index: -1;
  pointer-events: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
}

.progress_container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: var(--background-b);
  border-radius: 2rem 2rem 0 0;
  padding-top: 0;
}
.progress_container form .form-control {
  padding: 4rem 0;
}
.progress_container form .form-control ::-webkit-input-placeholder {
  color: var(--low-opacity-text);
}
.progress_container form .form-control ::-moz-placeholder {
  color: var(--low-opacity-text);
}
.progress_container form .form-control :-ms-input-placeholder {
  color: var(--low-opacity-text);
}
.progress_container form .form-control ::-ms-input-placeholder {
  color: var(--low-opacity-text);
}
.progress_container form .form-control ::placeholder {
  color: var(--low-opacity-text);
}
.progress_container form .form-control input[type=number] {
  background-color: transparent;
  color: var(--text-color);
  border: 0;
  font-size: 96px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  padding: 0;
  outline: 0;
  max-width: 100%;
  width: 100%;
  -webkit-user-select: text;
  -moz-user-select: text;
   -ms-user-select: text;
       user-select: text;
}
.progress_container form .form-control p {
  color: var(--low-opacity-text);
  text-align: center;
}
.progress_container form .form-control.datepicker {
  padding: 0;
  margin: 0 0 1.5rem;
  position: relative;
}
.progress_container form .form-control.datepicker input {
  background-color: transparent;
  padding: 0 1.5rem;
  color: var(--text-color);
  font-size: 1.2rem;
  height: 64px;
  width: 100%;
  max-width: 100%;
  text-align: left;
  outline: 0;
  border-radius: 8px;
  border: 1px solid var(--background-2);
}
.progress_container form .form-control.datepicker .MuiSvgIcon-root {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
  pointer-events: none;
}

.libray-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
}
.libray-tabs h2 {
  margin-bottom: 0;
  color: var(--low-opacity-text);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.libray-tabs .active {
  color: var(--text-color);
}

.container.filter-container {
  padding-right: 0;
  position: relative;
}
.container.filter-container::before {
  content: "";
  width: 2rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(29, 33, 44, 0)), to(var(--background-b)));
  background: linear-gradient(90deg, rgba(29, 33, 44, 0) 0%, var(--background-b) 100%);
  z-index: 9;
  pointer-events: none;
}

.filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 8px;
  overflow-x: scroll;
  position: relative;
}
.filters::-webkit-scrollbar {
  display: none;
}
.filters li {
  list-style-type: none;
  padding: 0.6rem 1.25rem;
  border: 1px solid var(--background-1);
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  white-space: nowrap;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
}
.filters li:last-of-type {
  margin-right: 1.5rem;
}
.filters li.active {
  background-color: var(--primary-b);
  border-color: var(--primary-b);
  color: var(--light-b);
}

#GoogleBooks {
  padding-bottom: 0;
}

#GoogleBooks .MuiIconButton-root,
#create-collection .MuiIconButton-root,
#log-page .MuiIconButton-root {
  color: var(--text-color) !important;
  background-color: var(--background-1);
}
#GoogleBooks .form-control,
#create-collection .form-control,
#log-page .form-control {
  padding: 1rem 0 0;
}
#GoogleBooks .form-control ::-webkit-input-placeholder, #create-collection .form-control ::-webkit-input-placeholder, #log-page .form-control ::-webkit-input-placeholder {
  color: var(--low-opacity-text);
}
#GoogleBooks .form-control ::-moz-placeholder, #create-collection .form-control ::-moz-placeholder, #log-page .form-control ::-moz-placeholder {
  color: var(--low-opacity-text);
}
#GoogleBooks .form-control :-ms-input-placeholder, #create-collection .form-control :-ms-input-placeholder, #log-page .form-control :-ms-input-placeholder {
  color: var(--low-opacity-text);
}
#GoogleBooks .form-control ::-ms-input-placeholder, #create-collection .form-control ::-ms-input-placeholder, #log-page .form-control ::-ms-input-placeholder {
  color: var(--low-opacity-text);
}
#GoogleBooks .form-control ::placeholder,
#create-collection .form-control ::placeholder,
#log-page .form-control ::placeholder {
  color: var(--low-opacity-text);
}
#GoogleBooks .form-control input[type=text],
#GoogleBooks .form-control input[type=search],
#GoogleBooks .form-control textarea,
#create-collection .form-control input[type=text],
#create-collection .form-control input[type=search],
#create-collection .form-control textarea,
#log-page .form-control input[type=text],
#log-page .form-control input[type=search],
#log-page .form-control textarea {
  background-color: transparent;
  color: var(--text-color);
  border: 0;
  font-size: 1.5rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: left;
  padding: 0;
  outline: 0;
  max-width: 100%;
  width: 100%;
  -webkit-user-select: text;
  -moz-user-select: text;
   -ms-user-select: text;
       user-select: text;
}
#GoogleBooks .form-control textarea,
#create-collection .form-control textarea,
#log-page .form-control textarea {
  font-size: 18px;
  color: var(--low-opacity-text);
}
#GoogleBooks .form-control p,
#create-collection .form-control p,
#log-page .form-control p {
  color: var(--low-opacity-text);
  text-align: center;
}

#create-collection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1.5rem;
}
#create-collection .collection-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#create-collection .collection-container .add-form {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}
#create-collection .collection-container .add-form #name {
  margin-bottom: 2rem;
}

#Header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
#Header .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}
#Header .right .MuiIconButton-root {
  color: var(--text-color) !important;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.1);
}
#Header .avatar {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  overflow: hidden;
}
#Header .avatar img {
  height: 40px;
  width: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#Header .sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--background-b);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem 1.5rem;
  z-index: 99;
}
#Header .sticky-header h1 {
  font-size: 1.25rem;
  width: 100%;
  text-align: center;
}

.page.statistics h2 {
  text-align: center;
}
.page.statistics .timeframe-total {
  margin-bottom: 2rem;
}
.page.statistics .stat-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1rem;
}
.page.statistics .stat-header h2 {
  margin-bottom: 0;
}
.page.statistics .stat-header .toggle-timeframe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
  opacity: 0.75;
}
.page.statistics small {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page.statistics .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0.5rem 0 0;
  gap: 0.5rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page.statistics .details.mb {
  margin-bottom: 1rem;
}
.page.statistics .details.mt {
  margin-top: 1rem;
}
.page.statistics .details .detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: var(--background-1);
  padding: 1.5rem 0;
  border-radius: 8px;
  gap: 4px;
}
.page.statistics .details .detail.wide {
  min-width: calc(50% - 0.5rem);
}
.page.statistics .details .detail.full {
  min-width: 100%;
}
.page.statistics .details .detail h2 {
  font-size: 2rem;
  margin-bottom: 0;
}
.page.statistics .details .detail sup,
.page.statistics .details .detail sub {
  font-size: 1rem;
  font-weight: 400 !important;
  color: var(--low-opacity-text);
}
.page.statistics .details .detail small {
  margin: 0;
}
.page.page-collection h1 {
  margin-bottom: 0.5rem;
  max-width: 95%;
}
.page.page-collection .description {
  color: var(--low-opacity-text);
  max-width: 90%;
}
.page.page-collection .collection-booklist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1rem 1fr;
  grid-template-columns: repeat(2, 1fr);
  -ms-grid-rows: 1fr 1rem 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-gap: 1rem;
}
.page.page-collection .collection-booklist > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.page.page-collection .collection-booklist > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.page.page-collection .collection-booklist > *:nth-child(3) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}
.page.page-collection .collection-booklist > *:nth-child(4) {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}
.page.page-collection .img-wrapper {
  position: relative;
}
.page.page-collection .img-wrapper a {
  height: 100%;
}
.page.page-collection .img-wrapper .bookRemove {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: red;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50px;
  opacity: 1;
  pointer-events: all;
}
.page.page-collection .img-wrapper .bookRemove span {
  background-color: white;
  width: 1.5rem;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.page.page-collection .img-wrapper .bookRemove span:first-of-type {
  -webkit-transform: translateX(-50%) rotate(-45deg);
          transform: translateX(-50%) rotate(-45deg);
}
.page.page-collection .img-wrapper .bookRemove span:last-of-type {
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.page.page-collection .collection-book {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}
.page.page-collection .collection-book.disabled {
  pointer-events: none;
}
.page.page-collection .collection-book img {
  width: 100%;
  border-radius: 8px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.page.page-collection .collection-book img.chosen {
  opacity: 0.1;
}

.icon-list {
  margin: 0;
  padding: 0;
}
.icon-list li {
  list-style-type: none;
  padding: 0.75rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  cursor: pointer;
}
.icon-list li .MuiSvgIcon-root {
  margin-right: 1rem;
}
.icon-list small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.icon-list small:not(:first-of-type) {
  margin-top: 1rem;
}
.icon-list .divider {
  width: 100%;
  height: 1.5rem;
  background-color: var(--text-color);
  opacity: 0;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2.5rem;
  background-color: var(--background-1);
  z-index: 9999;
  border-radius: 1rem 1rem 0 0;
}
.popup::before {
  content: "";
  width: 4rem;
  height: 4px;
  background-color: var(--background-1);
  position: absolute;
  top: -1rem;
  border-radius: 8px;
  z-index: 999;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  pointer-events: all;
}
.popup ul {
  margin: 0;
  padding: 0;
}
.popup ul li {
  list-style-type: none;
  padding: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.popup ul li .MuiSvgIcon-root {
  margin-right: 1rem;
}
.popup ul li.not-active {
  opacity: 0.5;
}
.popup__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.popup__header h2 {
  margin-bottom: 0;
}
.popup__header small {
  padding: 8px 12px;
  border: 1px solid var(--background-2);
  border-radius: 50px;
}

.chip-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  padding: 1.5rem 0;
}
.chip-list .chip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 12px;
  border: 1px solid var(--background-2);
  border-radius: 50px;
  font-size: 13px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.chip-list .chip.active {
  background-color: var(--primary-b);
}

.coming-soon {
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.coming-soon * {
  opacity: 0.3;
}
.coming-soon::before {
  content: "coming soon";
  position: absolute;
  top: 50%;
  right: 0;
  background: var(--primary-b);
  font-size: 9px;
  color: #fff;
  padding: 4px 8px;
  border-radius: 50px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 1 !important;
  z-index: 99;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.page_login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 0.5rem;
}
.page_login .logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 16px;
  padding-top: 20%;
}
.page_login .logo-container p {
  color: var(--low-opacity-text);
}
.page_login .login-detail {
  max-width: 100%;
  padding: 0 1.5rem;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page_login .button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
}
.page_login .button-group img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.page_login .button-group .coming-soon::before {
  right: 1rem;
}
.page_login .button-group .btn {
  position: relative;
  background-color: var(--light-b);
  color: #1d212c;
}

.color-picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.color-picker div {
  height: 48px;
  width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 100%;
  border-width: 4px;
  border-style: solid;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  cursor: pointer;
}
.color-picker div:before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.color-picker div.active::before {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0.5);
}

.react-datepicker {
  color: var(--text-color);
  background-color: var(--background-b);
  border: 0;
  border-radius: 1rem;
}
.react-datepicker .react-datepicker__header {
  background-color: var(--background-1);
  color: var(--text-color);
  border-radius: 1rem 1rem 0 0;
  border: 0;
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
  color: var(--text-color);
}
.react-datepicker .react-datepicker__header .react-datepicker__day-name {
  color: var(--text-color);
}
.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__time-name {
  color: var(--low-opacity-text);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary-b);
  border-radius: 100%;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary-b);
  border-radius: 100%;
  color: #fff !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  opacity: 0.3;
}

.empty-state {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.empty-state .img {
  margin-bottom: 1rem;
}
.empty-state.spacing {
  margin-top: 2rem;
}

.flex-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem;
}
.flex-link h2 {
  margin-bottom: 0;
}
.flex-link .MuiIconButton-root {
  color: var(--text-color);
}

.page-log {
  overflow: hidden;
}
.page-log h1 {
  margin-bottom: 1rem;
}
.page-log .btn-container {
  position: absolute;
  bottom: 0;
}

body .page#addNewForm,
#addNewForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 0;
}
body .page#addNewForm .main-image,
#addNewForm .main-image {
  max-width: 100%;
  min-height: 300px;
  border-radius: 12px;
  margin-bottom: 1rem;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.6;
  z-index: 0;
}
body .page#addNewForm .top-filter,
#addNewForm .top-filter {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(29, 33, 44, 0)), to(var(--background-b)));
  background: linear-gradient(180deg, rgba(29, 33, 44, 0) 0%, var(--background-b) 100%);
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
body .page#addNewForm .container.form-container,
#addNewForm .container.form-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--background-b);
  z-index: 1;
  padding-top: 0;
}
body .page#addNewForm .container.top_bar,
#addNewForm .container.top_bar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
body .page#addNewForm .container .add-form,
#addNewForm .container .add-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
body .page#addNewForm .form-control,
#addNewForm .form-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  position: relative;
}
body .page#addNewForm .form-control .open-modal,
#addNewForm .form-control .open-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border-radius: 12px;
}
body .page#addNewForm .form-control label,
#addNewForm .form-control label {
  border-radius: 12px;
  background-color: var(--background-1);
  width: 100%;
  padding: 1rem;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  pointer-events: all;
}
body .page#addNewForm .form-control label span,
#addNewForm .form-control label span {
  color: var(--low-opacity-text);
  font-size: 14px;
}
body .page#addNewForm .form-control label:focus-within,
#addNewForm .form-control label:focus-within {
  background-color: var(--background-2);
}
body .page#addNewForm .form-control label .react-select-container .react-select__control,
#addNewForm .form-control label .react-select-container .react-select__control {
  background-color: transparent;
  border: 0;
  padding: 0;
}
body .page#addNewForm .form-control label .react-select-container .react-select__value-container,
#addNewForm .form-control label .react-select-container .react-select__value-container {
  padding-left: 0;
}
body .page#addNewForm .form-control label .react-select-container .react-select__value-container .css-1wa3eu0-placeholder,
#addNewForm .form-control label .react-select-container .react-select__value-container .css-1wa3eu0-placeholder {
  color: var(--low-opacity-text);
}
body .page#addNewForm .form-control label .react-select-container .react-select__value-container .react-select__multi-value,
#addNewForm .form-control label .react-select-container .react-select__value-container .react-select__multi-value {
  background-color: var(--background-b);
  border-radius: 50px;
}
body .page#addNewForm .form-control label .react-select-container .react-select__value-container .react-select__multi-value .react-select__multi-value__label,
#addNewForm .form-control label .react-select-container .react-select__value-container .react-select__multi-value .react-select__multi-value__label {
  color: var(--text-color);
  padding: 12px;
  padding-left: 12px;
}
body .page#addNewForm .form-control label .css-2613qy-menu,
#addNewForm .form-control label .css-2613qy-menu {
  background-color: var(--background-1);
}
body .page#addNewForm .form-control label .css-2613qy-menu *,
#addNewForm .form-control label .css-2613qy-menu * {
  background-color: var(--background-1);
}
body .page#addNewForm .form-control input[type=text],
body .page#addNewForm .form-control input[type=number],
#addNewForm .form-control input[type=text],
#addNewForm .form-control input[type=number] {
  background-color: transparent;
  color: var(--text-color);
  border: 0;
  font-size: 18px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: left;
  padding: 0;
  outline: 0;
  max-width: 100%;
  color: var(--text-color);
  width: 100%;
  -moz-user-select: text;
   -ms-user-select: text;
       user-select: text;
  -webkit-user-select: text;
}
body .page#addNewForm .form-control input[type=text]#title,
body .page#addNewForm .form-control input[type=number]#title,
#addNewForm .form-control input[type=text]#title,
#addNewForm .form-control input[type=number]#title {
  font-size: 2rem;
  height: auto;
  padding: 1rem 0;
  color: var(--text-color);
}
body .page#addNewForm .form-control.hidden,
#addNewForm .form-control.hidden {
  display: none;
}
body .page#addNewForm .wrapper,
#addNewForm .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}
body .page#addNewForm .wrapper .form-control,
#addNewForm .wrapper .form-control {
  width: 100%;
}
body .page#addNewForm .wrapper .form-control label,
#addNewForm .wrapper .form-control label {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  padding: 1.5rem 0;
}
body .page#addNewForm .wrapper .form-control label input[type=number],
#addNewForm .wrapper .form-control label input[type=number] {
  text-align: center;
  font-size: 2rem;
}
body .page#addNewForm.bookEdit .form-container,
#addNewForm.bookEdit .form-container {
  background-color: transparent;
  padding: 0;
}
.editPopup .popup {
  background-color: var(--background-b);
  padding: 1.5rem 1.5rem;
}

.loader-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.loader {
  border: 4px solid var(--background-2);
  border-top: 4px solid var(--primary-b);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  -webkit-animation: spin 1.2s linear infinite;
          animation: spin 1.2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.collection-popup {
  padding: 0;
  padding-bottom: 0.5rem;
}
.collection-popup .CollectionAdd {
  padding: 1.25rem 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.collection-popup .CollectionAdd .circle {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50px;
  margin-right: 1rem;
  background-color: var(--background-2);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.collection-popup .CollectionAdd .circle::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  background-color: var(--light-b);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50px;
}
.collection-popup .CollectionAdd h2 {
  margin: 0;
}
.collection-popup .CollectionAdd.added .circle {
  background-color: var(--primary-b);
}
.collection-popup .CollectionAdd.added .circle::before {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.collection-popup .btn {
  margin-top: 0.5rem;
}

.gallery-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: var(--background-b);
}
.gallery-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--background-b);
  z-index: 999;
  padding: 2rem;
  width: calc(100% - 3rem);
  border-radius: 12px;
  text-align: center;
}
.modal h2 {
  margin-bottom: 2rem;
}
.modal .btn {
  font-size: 16px;
  height: 48px;
}
.modal .btn.outline {
  background-color: transparent;
  border: 1px solid var(--low-opacity-text);
}
.modal .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

input[data-readonly] {
  pointer-events: none;
}

::-webkit-input-placeholder {
  color: var(--low-opacity-text);
}

::-moz-placeholder {
  color: var(--low-opacity-text);
}

:-ms-input-placeholder {
  color: var(--low-opacity-text);
}

::-ms-input-placeholder {
  color: var(--low-opacity-text);
}

::placeholder {
  color: var(--low-opacity-text);
}

#library-search {
  width: 100%;
  height: 90px;
  background-color: var(--background-b) !important;
  border: 0;
  font-size: 28px;
  color: var(--text-color);
  outline: none;
  padding: 0;
}

#searchContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 9999;
}
#searchContainer #closeSearch {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 1.5rem;
  color: var(--text-color);
}

#profle-page {
  position: relative;
}
#profle-page .top_bar::before {
  content: "";
  width: 100%;
  background-color: var(--background-1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
#profle-page .icon-list small {
  margin-bottom: 0.5rem;
}
#profle-page .icon-list li {
  background-color: var(--background-1);
  padding-left: 1.5rem;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  font-size: 16px;
  height: 64px;
}
#profle-page .icon-list li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#profle-page .icon-list li.coming-soon::before {
  right: 1.5rem;
}
#profle-page .icon-list li:last-of-type {
  margin-bottom: 0;
}

#profile-banner {
  z-index: 99;
  position: relative;
}
#profile-banner .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#profile-banner .inner img {
  height: 96px;
  width: 96px;
  border-radius: 500px;
  border: 0;
  overflow: hidden;
  margin-bottom: 1rem;
}
#profile-banner .inner h2 {
  margin-bottom: 0.5rem;
}

#premium-add {
  background-color: var(--background-1);
  margin-bottom: 1.5rem;
  border-radius: 12px;
}
#premium-add p {
  font-size: 12px;
  opacity: 0.5;
}
#premium-add svg {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
#premium-add svg * {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
#premium-add .btn {
  margin-top: 1.5rem;
}
#premium-add .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 1rem;
}
#premium-add .content h2 {
  margin-bottom: 0.25rem;
}